import { Backdrop, createStyles, Fade, Modal, Theme } from '@material-ui/core';
import React from 'react';
import { Login } from '../index';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export function LoginModal(props: any) {
  const classes = useStyles();
  const { open, setOpen } = props;

  const LoginRef = React.forwardRef((props, ref) => <Login forwardRef={ref}/>)

  return (
    <Modal
      className={ classes.modal }
      open={ open }
      onClose={ () => setOpen(false) }
      closeAfterTransition
      BackdropComponent={ Backdrop }
      BackdropProps={ {
        timeout: 500,
      } }
    >
      <Fade in={ open }>
        <LoginRef/>
      </Fade>
    </Modal>
  );
}
