import React, { useState } from 'react';
import { Button, Card, CardContent, Divider, IconButton } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

import { createCSVFromXLS, CSVData } from './csv';

import './index.css';
import { createCSVFromCSV } from "./csv/createCSVFromCSV";

type FileProps = {
  file: File;
}

function download(name: string, { header, data }: CSVData) {
  const blob = new Blob([ header, ...data ], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.download = name;
  a.href = url;
  a.click();

  window.URL.revokeObjectURL(url);
}

const ROWS_LIMIT = 25000;


async function splitAndDownload(
  fileName: string,
  {
    header,
    data,
  }: {
    header: string;
    data: string[];
  },
): Promise<void> {
  for (let i = 0; i < Math.ceil(data.length / ROWS_LIMIT); ++i) {
    download(
      `${ fileName }_${ i + 1 }.csv`,
      {
        header,
        data: data.slice(i * ROWS_LIMIT, (i + 1) * ROWS_LIMIT),
      });
  }
}

async function downloadXLSX(file: File): Promise<void> {
  const content = await file.arrayBuffer();

  return splitAndDownload(
    file.name.replace(/\.xlsx?$/, ''),
    await createCSVFromXLS(content),
  );
}

async function downloadCSV(file: File): Promise<void> {
  const content = await file.text();

  return splitAndDownload(
    file.name.replace(/\.xlsx?$/, ''),
    await createCSVFromCSV(content),
  );
}

function FileButton({ file }: FileProps) {
  async function fileDownloadClickHandler() {
    await downloadXLSX(file);
  }

  return (
    <IconButton onClick={ fileDownloadClickHandler }>
      <CloudDownload/>
    </IconButton>
  );
}

function FileCard({ file }: FileProps) {
  return (
    <CardContent
      className="flex-space"
    >
      <span>{ file.name }</span>
      <FileButton file={ file }/>
    </CardContent>
  );
}

const XLSX_FILE_TYPES = [
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const CSV_FILE_TYPES = [
  '.csv',
  'text/csv',
]

export function Checkbox() {
  const fileTypes = [
    ...XLSX_FILE_TYPES,
    ...CSV_FILE_TYPES,
  ];

  const [ files, setFiles ] = useState<File[]>([]);

  function handleFileChange(event: any) {
    setFiles(Array.from(event.target.files));
  }

  return (
    <div>
      <Card>
        <CardContent className="flex-center">
          <Button
            variant="contained"
            component="label"
          >
            Upload File
            <input
              accept={ fileTypes.join(',') }
              type="file"
              hidden
              multiple
              onChange={ handleFileChange }
            />
          </Button>
        </CardContent>
      </Card>
      <br/>
      { files.length > 0 && (
        <Card>
          { files.map((file, i) => (
            <React.Fragment key={ i }>
              { i !== 0 && <Divider/> }
              <FileCard file={ file }/>
            </React.Fragment>
          )) }
        </Card>
      ) }
    </div>
  );
}
