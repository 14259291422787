import React from 'react';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import Chart from 'react-google-charts';

const style = {
  width: '100%',
  height: '500px',
  margin: '0.5em 0',
};

enum BME280 {
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  Humidity = 'Humidity',
}

export class Sensors extends React.Component<any, any> {
  readonly charts = {
    bme280: [ BME280.Pressure, BME280.Temperature, BME280.Humidity ],
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };
  }


  getOptions(name: string) {
    const palette = this.props.theme.palette;

    const background = palette.background.paper;
    const textColor = palette.text.primary;
    const gridColor = palette.type === 'dark' ? palette.grey['700'] : palette.grey['400'];

    const axisStyle = {
      textStyle: {
        color: textColor,
      },
      gridlines: {
        color: gridColor,
      },
    };

    return {
      title: name,
      titleTextStyle: axisStyle.textStyle,
      curveType: 'function',
      chartArea: {
        width: '90%',
        height: '80%',
      },
      backgroundColor: background,
      annotations: axisStyle,
      hAxis: {
        ...axisStyle,
        format: 'H:mm',
      },
      vAxis: axisStyle,
      legend: { position: 'none' },
    };
  }

  componentDidMount(): void {
    this.loadData();
  }

  async loadData() {
    this.setState({
      loaded: false,
    });

    const response = await fetch('https://api.andriy2.app/stat');
    const json = await response.json();

    const data = this.charts.bme280.reduce((s, e) => Object.assign(s, { [e]: [] }), {});

    const state = json.reduce((state: any, measurement: any) => {
      const { timestamp, humidity, temperature, pressure } = measurement;
      const date = new Date(timestamp);

      state[BME280.Humidity].push([ date, humidity ]);
      state[BME280.Temperature].push([ date, temperature.C ]);
      state[BME280.Pressure].push([ date, pressure.mmHg ]);

      return state;
    }, data);

    this.setState({
      ...state, loaded: true,
    });
  }

  render() {
    const allCharts = Array.prototype.concat.call([], ...Object.values(this.charts));

    return <div>
      <Backdrop open={ !this.state.loaded } style={ {
        zIndex: 999,
      } }>
        <CircularProgress color="inherit"/>
      </Backdrop>
      {
        allCharts.map(id =>
          <Paper
            id={ id }
            key={ id }
            style={ style }
            elevation={ 3 }
            hidden={ !this.state[id] }
          >
            <Chart
              chartType="LineChart"
              style={ style }
              data={ [
                [ 'Date', id ],
                ...(this.state[id] || []),
              ] }
              options={ this.getOptions(id) }
            />
          </Paper>,
        )
      }
    </div>;
  }
}
