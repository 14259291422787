import { Button, createStyles, TextField, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(1),
    },
  }),
);

export function UserLogin() {
  const classes = useStyles();

  return (
    <div>
      <div className={ classes.margin }>
        <TextField
          className={ classes.margin }
          fullWidth={ true }
          required={ true }
          variant={ 'outlined' }
          label={ 'Email' }
          type={ 'email' }
        />
        <TextField
          className={ classes.margin }
          fullWidth={ true }
          required={ true }
          variant={ 'outlined' }
          label={ 'Password' }
          type={ 'password' }
        />
      </div>
      <div className={ classes.margin }>
        <Button
          variant={ 'outlined' }
          size={ 'large' }
        >
          Sign in
        </Button>
      </div>
    </div>
  );
}
