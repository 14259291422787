import {
  FaApple,
  FaBitbucket,
  FaFacebookF,
  FaGithub,
  FaGoogle,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaWindows,
} from 'react-icons/all';
import { IconType } from 'react-icons/lib';

type SignInIcon = {
  icon: IconType;
  enabled?: boolean
}

export const AvailableServices: Array<Array<SignInIcon>> = [
  [
    { icon: FaGoogle },
    { icon: FaApple },
    { icon: FaWindows },
  ],
  [
    { icon: FaFacebookF },
    { icon: FaTelegramPlane },
    { icon: FaTwitter },
    { icon: FaLinkedinIn },
  ],
  [
    { icon: FaGithub },
    { icon: FaBitbucket },
  ],
];
