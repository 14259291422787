import React from 'react';
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { AttachMoney, Clear, Input } from '@material-ui/icons';

interface IPricesState {
  price: number;
  exchange: number;
  dense: boolean;
}

export class Prices extends React.Component<any, any> {
  private readonly defaultState: IPricesState;
  private readonly percent: number[];
  private element!: {
    price: React.RefObject<HTMLInputElement>;
    exchange: React.RefObject<HTMLInputElement>
  };

  constructor(props: any) {
    super(props);

    this.percent = [ 40, 30, 20, 0 ];
    this.defaultState = {
      price: 0,
      exchange: 1,
      dense: false,
    };

    this.state = {
      ...this.defaultState,
    };

    this.resize = this.resize.bind(this);
  }

  componentDidMount(): void {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    const dense = window.innerHeight < 500;

    if (dense !== this.state.dense) {
      this.setState({ dense });
    }
  }

  clearHandler(name: 'price' | 'exchange') {
    const element = this.element[name].current;
    if (!element) {
      return;
    }

    element.value = '';
    this.setState({
      [name]: this.defaultState[name],
    });

    element.focus();
  }

  inputHandler(name: 'price' | 'exchange', event: React.ChangeEvent<HTMLInputElement>) {
    const value = +event.target?.value;

    if (isNaN(value)) {
      return this.setState({
        [name]: 0,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  keyHandler(name: 'price' | 'exchange', event: React.KeyboardEvent<HTMLInputElement>) {
    const element = this.element[name].current;
    if (!element) {
      return;
    }

    const { keyCode } = event;

    if (keyCode === 32 || keyCode === 13) {
      this.clearHandler(name);
    }
  }

  calculate(percent: number, decimal: number) {
    let value = this.state.price * this.state.exchange / 100 * (100 + percent);
    return (Math.ceil(value * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal);
  }

  render() {

    this.element = {
      price: React.createRef<HTMLInputElement>(),
      exchange: React.createRef<HTMLInputElement>(),
    };


    return (
      <div>
        <TextField
          size={ this.state.dense ? 'small' : 'medium' }
          style={ {
            margin: '4px 0',
          } }
          variant="outlined"
          fullWidth={ true }
          inputRef={ this.element.price }
          inputProps={ {
            type: 'number',
          } }
          InputProps={ {
            startAdornment: (
              <InputAdornment position="start">
                <Input/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size={ this.state.dense ? 'small' : 'medium' }
                            onClick={ this.clearHandler.bind(this, 'price') }>
                  <Clear/>
                </IconButton>
              </InputAdornment>
            ),
          } }
          onKeyDown={ this.keyHandler.bind(this, 'price') }
          onChange={ this.inputHandler.bind(this, 'price') }
        />
        <TextField
          size={ this.state.dense ? 'small' : 'medium' }
          style={ {
            margin: '4px 0',
          } }
          variant="outlined"
          fullWidth={ true }
          inputRef={ this.element.exchange }
          inputProps={ {
            type: 'number',
          } }
          InputProps={ {
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size={ this.state.dense ? 'small' : 'medium' }
                            onClick={ this.clearHandler.bind(this, 'exchange') }>
                  <Clear/>
                </IconButton>
              </InputAdornment>
            ),
          } }
          onKeyDown={ this.keyHandler.bind(this, 'exchange') }
          onChange={ this.inputHandler.bind(this, 'exchange') }
        />

        <TableContainer component={ Paper } style={ { margin: '4px 0' } }>
          <Table
            size={ this.state.dense ? 'small' : 'medium' }
            style={ {
              width: '100%',
              tableLayout: 'fixed',
            } }>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  style={ {
                    width: '40px',
                  } }
                >
                  <b>%</b>
                </TableCell>
                <TableCell align="right" component="th">
                  <b>.xx</b>
                </TableCell>
                <TableCell align="right" component="th">
                  <b>.00</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.percent.map(percent =>
                  <TableRow key={ percent }>
                    <TableCell component="th" scope="row">
                      <b>
                        { percent }
                      </b>
                    </TableCell>

                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      { this.calculate(percent, 2).toString() }
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      { this.calculate(percent, 0).toString() }
                    </TableCell>
                  </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
