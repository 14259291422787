import React, { Dispatch, SetStateAction } from 'react';

function useStorageState(storage: Storage, key: string, initialState: string = ''): [ string, Dispatch<SetStateAction<string>> ] {
  const [ value, setValue ] = React.useState(
    storage.getItem(key) || initialState,
  );

  React.useEffect(() => {
    storage.setItem(key, value);
  }, [ key, value, storage ]);

  return [ value, setValue ];
}

export function useLSState(key: string, initialState?: string) {
  return useStorageState(localStorage, key, initialState);
}

export function useSSState(key: string, initialState?: string) {
  return useStorageState(sessionStorage, key, initialState);
}
