import XLSX from 'xlsx';

import { makeHeaderRow, makeItemRow, PriceData } from './utils';
import { CSVData, filterItem } from '.';
import { Config } from './Config';

import { config as confKiro } from './confKiro';

export async function createCSVFromXLS(content: ArrayBuffer, config: Config = confKiro): Promise<CSVData> {
  const xlsx = XLSX.read(content);

  const { Sheets, SheetNames } = xlsx;
  const [ SheetName ] = SheetNames;

  const sheet = Sheets[SheetName];

  const json: PriceData[] = XLSX.utils.sheet_to_json(sheet, {
    raw: true,
    header: config.header,
  });

  const header = makeHeaderRow();
  const data = json.reduce<string[]>((result, row) => {
    if (!filterItem(row)) {
      result.push(makeItemRow(row));
    }

    return result;
  }, []);

  return { header, data };
}
