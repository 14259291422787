import React from 'react';
import './index.css';

export function Nginx() {
  return (
    <div className="nginx">
      <h1>Welcome to nginx!</h1>
      <p>
        If you see this page, the nginx web server is successfully installed and
        working. Further configuration is required.
      </p>

      <p>
        For online documentation and support please refer to <a href="//nginx.org/">nginx.org</a>.<br/>
        Commercial support is available at <a href="//nginx.com/">nginx.com</a>.
      </p>

      <p>
        <em>
          Thank you for using nginx.
        </em>
      </p>
    </div>
  );
}
