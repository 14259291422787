import React from 'react';
import { Container, createStyles, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceLogin } from './ServiceLogin';
import { UserLogin } from './UserLogin';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      outline: 0,
    },
    center: {
      alignContent: 'center',
      textAlign: 'center' as 'center',
      padding: theme.spacing(3),
    },
    or: {
      marginTop: theme.spacing(1),
    },
  }),
);

export function Login(props: any) {
  const classes = useStyles();

  const { forwardRef } = props;

  return (
    <Container
      maxWidth={ 'xs' }
      className={ classes.root }
      tabIndex={ -1 }
      ref={ forwardRef }
    >
      <Paper className={ classes.center }>
        <Typography variant="h4" component="h2">
          Sign in
        </Typography>

        <UserLogin/>

        <Typography
          color="textSecondary"
          className={ classes.or }
        >
          or
        </Typography>

        <ServiceLogin/>
      </Paper>
    </Container>
  );
}

