import React from 'react';
import { IconButton } from '@material-ui/core';
import { AvailableServices } from './AvailableServices';

export function ServiceLogin() {
  return (
    <div>
      {
        AvailableServices.map((signInList, i) => (<div key={ i }>
          {
            signInList.map(
              (signInElement, j) =>
                <IconButton
                  key={ j }
                  disabled={ !signInElement.enabled }
                >
                  <signInElement.icon/>
                </IconButton>,
            )
          }
        </div>))
      }
    </div>
  );
}
