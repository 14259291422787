import React from 'react';
import { isDark } from '../utils/theme';
import { common, grey, orange, red } from '@material-ui/core/colors';
import { createTheme, Theme } from '@material-ui/core/styles';

const darkMode = isDark();

const defaultTheme = {
  primary: {
    500: grey[900],
    900: grey[900],
  },
  secondary: orange,
  error: red,
};

const lightTheme = createTheme({
  palette: {
    ...defaultTheme,
    primary: {
      500: common.white,
      900: grey[900],
    },
    type: 'light',
  },
});

const darkTheme = createTheme({
  palette: {
    ...defaultTheme,
    primary: {
      500: grey[900],
      900: grey[900],
    },
    type: 'dark',
  },
});

export class Themeable<P, S> extends React.Component<P, S | { theme: Theme }> {
  constructor(props: any) {
    super(props);

    this.state = {
      theme: darkMode.matches ? darkTheme : lightTheme,
    };

    this.onThemeChange = this.onThemeChange.bind(this);
  }

  onThemeChange() {
    this.setTheme(darkMode.matches);
  }

  setTheme(dark: boolean) {
    this.setState({
      theme: dark ? darkTheme : lightTheme,
    });
  }

  componentDidMount(): void {
    darkMode.addEventListener('change', this.onThemeChange);
  }

  componentWillUnmount(): void {
    darkMode.removeEventListener('change', this.onThemeChange);
  }
}
