import { PriceData } from "./utils";

export function filterItem(row: PriceData): boolean {
  return !+row.price;
}

export type CSVData = {
  header: string;
  data: string[];
};

export * from './createCSVFromXLS';
export * from './createCSVFromCSV';
