import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Themeable } from './Components/Themeable';
import { Nginx } from './Nginx';
import { Prices } from './Prices';
import { Sensors } from './Sensors';
import { REFUsol } from './REFUsol';
import { Header } from './Header';
import { Error } from './Error';
import { Checkbox } from './Checkbox';
import { About } from './About';
import { User } from './User';

import './App.css';

export class App extends Themeable<any, any> {
  render() {
    return (
      <div>
        <ThemeProvider theme={ this.state.theme }>
          <CssBaseline/>
          <Header/>
          <Container
            component={ 'main' }
            maxWidth={ 'md' }
          >
            <Switch>
              <Route exact path={ '/' }>
                <Nginx/>
              </Route>
              <Route path={ '/prices' }>
                <Prices/>
              </Route>
              <Route path={ '/sensors' }>
                <Sensors theme={ this.state.theme }/>
              </Route>
              <Route path={ '/refu' }>
                <REFUsol theme={ this.state.theme }/>
              </Route>
              <Route path={ '/checkbox' }>
                <Checkbox/>
              </Route>
              <Route path={ '/about' }>
                <About/>
              </Route>
              <Route path={ '/user' }>
                <User/>
              </Route>
              <Route>
                <Error/>
              </Route>
            </Switch>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}
