import React from 'react';
import { Card, CardActions, CardContent, createStyles, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandable: {
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expand: {
      transform: 'rotate(0deg)',
    },
    expanded: {
      transform: 'rotate(180deg)',
    },
  }),
);

export function About() {
  const classes = useStyles();

  const now = new Date();
  const birthday = new Date('1997-12-26T15:30:00.000Z');

  const years = Math.floor((now.getTime() - birthday.getTime()) / (365.2425 * 24 * 60 * 60 * 1000));

  const [ expanded, setExpanded ] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            { 'andriy2' }
          </Typography>
          <Typography color="textSecondary">
            { years } { 'y.o., Kyiv, UA' }
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton
            className={ clsx(classes.expandable, expanded ? classes.expanded : classes.expand) }
            color="inherit"
            onClick={ handleExpandClick }
          >
            <ExpandMore/>
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
}
