import React from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { AccountCircle, CodeOutlined, DescriptionOutlined, Menu } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LoginModal } from '../Login/Modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export function Header() {
  const classes = useStyles();

  const [ loginOpen, setLoginOpen ] = React.useState(false);

  return (
    <AppBar position="sticky" className={ classes.root }>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={ classes.menuButton }
        >
          <Menu/>
        </IconButton>
        <Typography
          variant="h6"
          className={ classes.title }
        >
          { 'andriy2' }
        </Typography>
        <div>
          <IconButton color="inherit" href={ 'https://github.com/Andriy2/' }>
            <CodeOutlined/>
          </IconButton>
          <IconButton color="inherit" href={ '/about' }>
            <DescriptionOutlined/>
          </IconButton>
          <IconButton edge="end" color="inherit" onClick={ () => setLoginOpen(true) }>
            <AccountCircle/>
          </IconButton>
        </div>
      </Toolbar>
      <LoginModal
        open={ loginOpen }
        setOpen={ (state: boolean) => setLoginOpen(state) }
      />
    </AppBar>
  );
}
