import React from 'react';
import { Card, CardActions, CardContent, Container, IconButton, Typography } from '@material-ui/core';
import { STATUS_CODES } from 'http';
import { ArrowBack, Home, Refresh } from '@material-ui/icons';

export function Error() {
  return (
    <Container
      maxWidth={ 'sm' }
    >
      <Card>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            { STATUS_CODES[404] }
          </Typography>
          <Typography variant="h3" component="h2" color="error">
            404
          </Typography>
          <Typography color="textSecondary">
            Requested url: <Typography component="span" color="textPrimary">{ window.location.pathname }</Typography>
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton
            color="inherit"
            onClick={ () => window.history.back() }
          >
            <ArrowBack/>
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={ () => window.location.reload() }
          >
            <Refresh/>
          </IconButton>
          <IconButton
            style={ {
              marginLeft: 'auto',
            } }
            color="inherit"
            href={ '/' }
          >
            <Home/>
          </IconButton>
        </CardActions>
      </Card>
    </Container>
  );
}
