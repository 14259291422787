interface ConfigProps {
  header: string[];
}

export class Config {
  header: string[];

  constructor({ header }: ConfigProps) {
    this.header = header;
  }
}
