// ---------------------------------------------------------------- //

export enum KeyPropValueMap {
  good_name = 'ім\'я товару',
  code = 'код',
  group = 'група товарів',
  barcode = 'штрихкод',
  uktzed = 'уктзед',
  price = 'ціна',
  is_weight = 'ваговий товар',
  type = 'тип товару',
  count = 'залишок',
}

// ---------------------------------------------------------------- //

export type PriceData = {
  good_name: string;
  code: string;
  group?: string;
  barcode?: string;
  uktzed?: string;
  price: number;
  is_weight?: boolean;
  type?: 'goods' | 'service';
}

const defaults: Required<PriceData> = {
  good_name: '-',
  code: '-',
  group: '',
  barcode: '',
  uktzed: '',
  price: 0,
  is_weight: false,
  type: 'goods',
};

// ---------------------------------------------------------------- //

const multiplier = 1.4;
const header: Array<keyof PriceData> = [ 'code', 'good_name', 'price', 'type' ];
const endl = '\n';

// ---------------------------------------------------------------- //

function headerItemConvert(key: keyof PriceData): string {
  return KeyPropValueMap[key] || key;
}

function calcPrice(price: number, multiplier: number) {
  return Math.ceil(price * multiplier);
}

type PriceDataFormatter = {
  [Key in keyof PriceData]?: (key: PriceData[Key]) => string | number;
};

const formatter: PriceDataFormatter = {
  good_name: (txt: string) => `"${ txt.replace(/"/g, '""') }"`,
  price: (price: number) => calcPrice(price, multiplier),
  is_weight: (isWeight?: boolean) => isWeight ? 'True' : 'False',
};

function formatItem(item: PriceData): Array<string | number> {
  return header.map((key) => (
    item[key] ? (
      formatter[key] ?
        formatter[key]!(item[key] as never) : item[key]
    ) : defaults[key]
  ) as number | string);
}

// ---------------------------------------------------------------- //

export function makeItemRow(item: PriceData, separator = ','): string {
  return `${ formatItem(item).join(separator) }${ endl }`;
}

export function makeHeaderRow(separator = ','): string {
  return `${ header.map(headerItemConvert).join(separator) }${ endl }`;
}

// ---------------------------------------------------------------- //
