import React from 'react';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import Chart from 'react-google-charts';

const style = {
  width: '100%',
  height: '500px',
  margin: '0.5em 0',
};

enum REFUsolStat {
  K13 = '192.168.130.13',
  K17 = '192.168.130.17',
}

const mapKeyName: Record<string, string> = {};

export class REFUsol extends React.Component<any, any> {
  readonly charts = {
    refusol: [ REFUsolStat.K17, REFUsolStat.K13 ],
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };
  }


  getOptions(name: string) {
    const palette = this.props.theme.palette;

    const background = palette.background.paper;
    const textColor = palette.text.primary;
    const gridColor = palette.type === 'dark' ? palette.grey['700'] : palette.grey['400'];

    const axisStyle = {
      textStyle: {
        color: textColor,
      },
      gridlines: {
        color: gridColor,
      },
    };

    return {
      title: name,
      titleTextStyle: axisStyle.textStyle,
      chartArea: {
        width: '90%',
        height: '80%',
      },
      backgroundColor: background,
      annotations: axisStyle,
      hAxis: {
        ...axisStyle,
        format: 'MMMM d',
      },
      vAxis: axisStyle,
      legend: { position: 'none' },
      enableInteractivity: false,
    };
  }

  componentDidMount(): void {
    this.loadData();
  }

  async loadData() {
    this.setState({
      loaded: false,
    });

    const response = await fetch('https://api.andriy2.app/refu-stat-daily');
    const json = await response.json();

    const data = this.charts.refusol.reduce((s, e) => Object.assign(s, { [e]: [] }), {});

    const state = json.reduce((state: any, measurement: any) => {
      const { timestamp, daily, name, _id: { host } } = measurement;
      const graphId = host;

      const lastTime = new Date(timestamp).getTime();
      const date = new Date(lastTime - lastTime % 86400000);

      if (!mapKeyName[graphId]) {
        mapKeyName[graphId] = name;
      }

      state[graphId].push([ date, daily, daily ]);

      return state;
    }, data);

    this.setState({ ...state, loaded: true, });
  }

  render() {
    const allCharts = Array.prototype.concat.call([], ...Object.values(this.charts));

    return <div>
      <Backdrop open={ !this.state.loaded } style={ {
        zIndex: 999,
      } }>
        <CircularProgress color="inherit"/>
      </Backdrop>
      {
        allCharts.map(id =>
          <Paper
            id={ id }
            key={ id }
            style={ style }
            elevation={ 3 }
            hidden={ !this.state[id] }
          >
            <Chart
              chartType="ColumnChart"
              style={ style }
              data={ [
                [ 'Date', mapKeyName[id], { role: 'annotation' } ],
                ...(this.state[id] || []),
              ] }
              options={ this.getOptions(mapKeyName[id]) }
            />
          </Paper>,
        )
      }
    </div>;
  }
}
