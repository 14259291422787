import React from 'react';

import { Card, CardActions, CardContent, createStyles, IconButton, Theme, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useLSState, useSSState } from '../utils/storage';
import { LoginModal } from '../Login/Modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    left: {
      marginLeft: 'auto',
    },
  }),
);

export function User() {
  const classes = useStyles();

  const [ ls ] = useLSState('ls', `${ ~~(Math.random() * 100) }`);
  const [ ss ] = useSSState('ss', `${ ~~(Math.random() * 100) }`);
  const [ modalOpen, setModalOpen ] = React.useState(false);

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            { 'Login' }
          </Typography>
          <Typography color="textSecondary">
            <br/>
            LocalStorage: { ls }
            <br/>
            SessionStorage: { ss }
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton
            className={ classes.left }
            onClick={ () => setModalOpen(true) }
          >
            <AccountCircle/>
          </IconButton>
        </CardActions>
      </Card>
      <LoginModal
        open={ modalOpen }
        setOpen={ (state: boolean) => setModalOpen(state) }
      />
    </div>
  );
}
